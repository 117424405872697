$('form#get-in-touch__form').on('submit', function (e) {
	e.preventDefault();

	$(this).addClass('ajaxing');

	var formData = new FormData($(this)[0]);

	$.ajax({
		type: 'POST',
		url: ajaxurl, // eslint-disable-line
		data: formData,
		contentType: false,
		processData: false,
		cache: false,
		success: function (ajax_response) {
			console.log(ajax_response);
			$('form#get-in-touch__form').removeClass('ajaxing');
			if (ajax_response == 'success') {
				let success_message = $('form#get-in-touch__form template#get-in-touch--sucess').html();
				$('.get-in-touch__title').hide();
				$('.get-in-touch__content').hide();
				$('form#get-in-touch__form').html(success_message);
			} else {
				let failed_message = $('form#get-in-touch__form template#get-in-touch--fail').html();
				$('.get-in-touch__title').hide();
				$('.get-in-touch__content').hide();
				$('form#get-in-touch__form').html(failed_message);
			}
		},

	});

});

$('form#shortlist__form').on('submit', function (e) {
	e.preventDefault();

	$(this).addClass('ajaxing');

	var formData = new FormData($(this)[0]);

	$.ajax({
		type: 'POST',
		url: ajaxurl, // eslint-disable-line
		data: formData,
		contentType: false,
		processData: false,
		cache: false,
		success: function (ajax_response) {
			$('form#shortlist__form').removeClass('ajaxing');
			if (ajax_response == 'success') {
				let success_message = $('form#shortlist__form template#shortlist--sucess').html();
				$('.shortlist__content').html(success_message);
			} else {
				let failed_message = $('form#shortlist__form template#shortlist--fail').html();
				$('.shortlist__content').html(failed_message);
			}
		},

	});

});