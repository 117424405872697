// Add to shortlist click
$(document).on('click', '.add-to-shortlist', function (e) {
	e.preventDefault();
	// Get voice id
	let vid = $(this).data('vid');

	let duplicate = false;

	// Check if there is any shortlist already
	if ($('#voices-shortlist ul li').length) {
		// Loop through each shortlist item to check for duplicates
		$('#voices-shortlist ul li').each(function (i, shortlist_item) {
			console.log(shortlist_item, i);
			if ($(shortlist_item).data('vid') == vid) {
				duplicate = true;
			}
		});
		// If there is a duplicate don't add
		if (duplicate == false) {
			add_to_shortlist(this);
		} else {
			$('#voices-shortlist').addClass('active');
		}
	} else {
		add_to_shortlist(this);
	}
});

// Add id to shortlist cookie array
function add_to_shortlist(voice) {
	let voice_data = {
		'vid': $(voice).data('vid'),
		'action': 'add_to_shortlist',
	};

	$.ajax({
		type: 'POST',
		url: ajaxurl, // eslint-disable-line
		data: voice_data,
		success: function (ajax_response) {
			// Change button to shortlisted
			if($(voice).hasClass('btn--secondary')) {
				$(voice).text('Remove Artist');
			} else {
				$(voice).text('Shortlisted');
			}
			// Change class for styling
			if($(voice).hasClass('btn--voice-shortlist')) {
				$(voice).removeClass('btn--voice-shortlist');
				$(voice).addClass('btn--voice-shortlisted');
			}
			// Change class for scripts
			$(voice).removeClass('add-to-shortlist');
			$(voice).addClass('remove-from-shortlist');
			
			// Update counter
			update_shortlist_counter(ajax_response.shortlist_count);
			
			// Update shortlist-item shortlist
			$('#shortlist-item__shortlist--empty').hide();
			$('.shortlist-item__shortlist-content').replaceWith(ajax_response.shortlist_html);
		},
	});

}

// Remove from shortlist click
$(document).on('click', '.remove-from-shortlist', function (e) {

	e.preventDefault();
	
	let vid = $(this).data('vid');
	let voice = this;

	if($('#voices-listing').length) {
		voice = $('#voices-listing').find(`[data-vid='${vid}']`);
	}

	remove_from_shortlist(voice);

});

// Remove id to shortlist cookie array
function remove_from_shortlist(voice) {
	let voice_data = {
		'vid': $(voice).data('vid'),
		'action': 'remove_from_shortlist',
	};

	$.ajax({
		type: 'POST',
		url: ajaxurl, // eslint-disable-line
		data: voice_data,
		success: function (ajax_response) {

			if (ajax_response != 'fail') {
				// Change button to shortlist
				$(voice).text('Shortlist');
				// Change class for styling
				if($(voice).hasClass('btn--voice-shortlisted')) {
					$(voice).removeClass('btn--voice-shortlisted');
					$(voice).addClass('btn--voice-shortlist');
				}
				
				// Change class for scripts
				$(voice).removeClass('remove-from-shortlist');
				$(voice).addClass('add-to-shortlist');
				
				// On single voice page remove shortlisted from button
				var voices = $(document).find('.remove-from-shortlist[data-vid="' + $(voice).data('vid') + '"]');
				voices.each( function() {
					$(this).text('Shortlist');
					$(this).removeClass('remove-from-shortlist');
					$(this).addClass('add-to-shortlist');
				});

				// Show empty message if no voices in shortlist
				if(ajax_response.shortlist_count == 0) {
					$('#shortlist-item__shortlist--empty').show();
				}

				// Update counter
				update_shortlist_counter(ajax_response.shortlist_count);

				// Update shortlist-item shortlist
				$('.shortlist-item__shortlist-content').replaceWith(ajax_response.shortlist_html);
			}
		},
	});

}

// Create shortlist counter
function update_shortlist_counter(count = null) {
	let shortlistElem = $('#menu-header-navigation li:last-of-type a');
	let shortlistCount = '';
	if (count || count == 0) {
		shortlistCount = count;
	} else {
		shortlistCount = $('#footer__shortlist-counter').attr('data-shortlist');
	}
	let shortlistCounter = $('<div class="shortlist-counter">' + shortlistCount + '</div>');

	if ($('#menu-header-navigation li:last-of-type .shortlist-counter').length) {
		$('#menu-header-navigation li:last-of-type .shortlist-counter').text(shortlistCount);
	} else {
		shortlistElem.after(shortlistCounter);
	}
}
update_shortlist_counter();
