export default {
  init() {
    // JavaScript to be fired on the about us page

	
  },
  finalize() {
	$('.representation__listing').slick({
		arrows: false,
		dots: false,
		infinite: false,
		slidesToShow: 3,
		rows: 0,
		slide: '.representation-slide__section',
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 2,
					dots: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					dots: true,
				},
			},
		],
	});
  },
};
