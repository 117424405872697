// Get time from audio (given in seconds)
const calculateTime = (secs) => {
	const minutes = Math.floor(secs / 60);
	const seconds = Math.floor(secs % 60);
	const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
	return `${minutes}:${returnedSeconds}`;
}

// Change duration to show calculated time
const displayDuration = function (element) {
	const durationElement = element.siblings('.time__text').children('.time--duration');
	durationElement.html(calculateTime(element[0].duration));
}

// Use maximum play time to set a max on slider
const setSliderMax = function (element, seekSlider) {
	seekSlider.max = Math.floor(element[0].duration);
}

// Update slider with played time
const showRangeProgress = (seekSlider, audioPlayerContainer) => {
	audioPlayerContainer.style.setProperty('--seek-before-width', seekSlider.value / seekSlider.max * 100 + '%');
}

// Loop through audio players on page
const audio = $('.audio');

audio.each(function () {
	var element = $(this);
	// Get duration slider
	const seekSlider = element.siblings('.seek-slider');
	// Get audio container
	const audioPlayerContainer = element.parent('.voice__audio-player');
	// Get current time element
	const currentTimeContainer = element.siblings('.time__text').children('.time--current');
	// Get play/pause button
	const playButton = element.siblings('.play-icon');

	// When audio has metadata loaded
	element.on('loadedmetadata play', function () {
		// Display total duration
		displayDuration(element);
		// Use the total duration as the slider max
		setSliderMax(element, seekSlider);
	});

	// Monitor slider for changes
	seekSlider[0].addEventListener('input', () => {
		// Show watched section on manual change
		showRangeProgress(seekSlider[0], audioPlayerContainer[0]);
		currentTimeContainer.html(calculateTime(seekSlider[0].value));
		// Show watched section on automatic change
		element[0].currentTime = seekSlider[0].value;
	});

	// Monitor audio for changes
	element[0].addEventListener('timeupdate', () => {
		seekSlider[0].value = element[0].currentTime;
		showRangeProgress(seekSlider[0], audioPlayerContainer[0]);
		currentTimeContainer.html(calculateTime(element[0].currentTime));
	});

	// On pause/play button
	playButton[0].addEventListener('click', () => {
		// Get the current status whether its playing or paused
		let status = playButton[0].getAttribute('data-status');

		// Change icon showing to represent if audio is paused or playing
		const playIcon = playButton.find('#play-button');
		const pauseIcon = playButton.find('#pause-button');

		if (status === 'paused') { // If user clicks they are trying to play
			// play audio and show paused icon
			pauseIcon[0].style.display = 'block';
			playIcon[0].style.display = 'none';
			playButton[0].setAttribute('data-status', 'playing');
			element[0].play();
		} else { // If playing then user is trying to pause
			// pause audio and show play icon
			playIcon[0].style.display = 'block';
			pauseIcon[0].style.display = 'none';
			playButton[0].setAttribute('data-status', 'paused');
			element[0].pause();
		}
	});

})