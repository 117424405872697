import 'select2'; // https://select2.org/
import 'slick-carousel';
//import gsap data
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// get audio player JS
import './audioplayer';
// get shortlist JS
import './shortlist';
// get forms JS
import './forms';

export default {
	init() {
		// JavaScript to be fired on all pages

		// The total time taken for the document to be ready - https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
		var loadTime = 1000; // Fallback if performance not supported
		if (PerformanceObserver.supportedEntryTypes.includes('navigation')) {
			loadTime = performance.getEntriesByType('navigation')[0].domInteractive;
		}
		// We want the preload to be active for at least 1 second to avoid elements flickering, so add timeout if document ready time is less than 1 second.
		var timeout = 0;
		if (loadTime < 1000) {
			timeout = 2000 - loadTime;
		}
		setTimeout(function () {
			document.querySelector('.preload__loader').classList.remove('active');
			document.querySelector('.preload').classList.remove('active');
		}, timeout);

		$('.header__nav--mobile-btn').on('click', function () {
			$('.container--header').toggleClass('container--header--open');
			$('.header__nav--mobile-btn').toggleClass('header__nav--mobile-btn--open');

			if ($('.container--header--open').length) {
				$('html, body').css({
					overflow: 'hidden',
					height: '100%',
				});
			} else {
				$('html, body').css({
					overflow: 'auto',
					height: 'unset',
				});
			}
		});

		$('#constant-contact').on('click', function () {
			$('.get-in-touch').addClass('get-in-touch--open');
			$('#get-in-touch__btn').addClass('get-in-touch__btn--open');
			$('html, body').css({
				'overflow-y': 'hidden',
				height: '100%',
			});
		});

		$('#get-in-touch__btn').on('click', function () {
			$('.get-in-touch').removeClass('get-in-touch--open');
			$('#get-in-touch__btn').removeClass('get-in-touch__btn--open');
			$('html, body').css({
				'overflow-y': 'auto',
				height: 'unset',
			});
		});

		// Search voices form
		$('.search__input').select2({
			placeholder: $(this).data('placeholder'),
			selectionCssClass: 'search__field',
			allowClear: true,
		});

		$('.search__input').on('select2:open', function () {
			$('input.select2-search__field').prop('placeholder', 'Start Typing');
		});

		$('.search__input').on('select2:select', function () {
			$(this).closest('.search__fieldset').find('.chevron-icon').hide();
		});

		$('.search__input').on('select2:clear', function () {
			$(this).closest('.search__fieldset').find('.chevron-icon').show();
		});

		$('#select2-selection--subaccent').on('click', function (e) {
			e.preventDefault();
			var $searchfield = $(this).parent().find('.select2-search__field');
			$searchfield.prop('disabled', true);
		});

		/* Get in touch button */
		var fixedIconEndTl = gsap.timeline({
			scrollTrigger: {
				trigger: '.footer__pattern',
				start: 'bottom bottom',
				end: 'bottom bottom',
				toggleActions: 'play none reverse none',
			},
		});
		fixedIconEndTl.to('#constant-contact', { autoAlpha: 0, x: 100, ease: 'power2.inOut', duration: 0.4 });
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		// $('.rand-border').each(function () {
		// 	// Generate a random whole number up to 15
		// 	let randNumber = Math.floor(Math.random() * 15 + 1);
		// 	// Add rand-border--[random numer] class
		// 	$(this).addClass('rand-border--' + randNumber);
		// });

		$('.voice__preview').on('click', function () {
			$(this).closest('.voice-card__container').find('.voice-card__data').toggleClass('d-flex');
			$(this).closest('.voice-card__container').find('.voice-card__data').toggleClass('d-none');
			$(this).closest('.voice-card__container').find('.voice-card__image').toggle();
			$(this).toggleClass('background-color--secondary');
			$(this).toggleClass('background-color--primary');
		});

		$('.voice-data__close').on('click', function () {
			$(this).closest('.voice-card__container').find('.voice-card__data').toggleClass('d-flex');
			$(this).closest('.voice-card__container').find('.voice-card__data').toggleClass('d-none');
			$(this).closest('.voice-card__container').find('.voice-card__image').show();
			$(this).closest('.voice-card__container').find('.voice__preview').toggleClass('background-color--secondary');
			$(this).closest('.voice-card__container').find('.voice__preview').toggleClass('background-color--primary');
		});

		// If mobile text is shown
		if ($('.search__text--mobile').first().is(':hidden') == false) {
			// Watch for clicks to then hide mobile text and show form
			$('.search__content:not(.search__content--acent)').on('click', function () {
				$(this).find('.search__text--mobile').hide();
				$(this).find('.search__form').show();
			});
			// Watch for clicks to then hide mobile text and show form
			$('.search__content--accent').on('click', function () {
				$(this).find('.search__text--mobile').hide();
				$(this).find('.search__form').show();
			});
		}

		// Scrolling Text
		var scrollingTextContainer = $('.scrolling-text');

		// If there is scrolling text on page
		if (scrollingTextContainer.length > 0) {
			const scrollingText = $('.scrolling-text--whats-new');

			// Scroll by -100% when the bottom of the trigger hits the bottom of the scrollbar
			gsap.to(scrollingText, {
				scrollTrigger: {
					trigger: scrollingTextContainer,
					start: 'bottom bottom',
					scrub: 1,
				},
				xPercent: -100,
				duration: 5,
			});
		}

		// If there is scrolling text on page
		if (scrollingTextContainer.length > 0) {
			const scrollingText = $('.scrolling-text--join-us');

			// Scroll by -100% when the bottom of the trigger hits the bottom of the scrollbar
			gsap.to(scrollingText, {
				scrollTrigger: {
					trigger: scrollingTextContainer,
					start: 'bottom bottom',
					scrub: 1,
				},
				xPercent: -100,
				duration: 5,
			});
		}
	},
};
