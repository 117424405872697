export default {
	init() {
		// JavaScript to be fired on the archive voices page
		$('.tags__tag').on('click', function(){
			// Get closest radio button
			var radio = $(this).find('input:radio');

			// If radio is already checked, uncheck
			if($(this).hasClass('tags__tag--checked')) {
				radio.prop('checked', false);
			}
			
			$('#search-filter').submit();
			
		});

	},
	finalize() {
		
	},
};
