// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import contactUs from './routes/contact';
import postTypeArchiveVoice from './routes/postTypeArchiveVoice';

/** Populate Router instance with DOM routes */
const routes = new Router({
	// All pages
	common,
	// Home page
	home,
	// About Us page, note the change from about-us to aboutUs.
	aboutUs,
	contactUs,
	postTypeArchiveVoice,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
