export default {
  init() {
    // JavaScript to be fired on the about us page
	$('.team-members__read-more').on('click', function() {
		var teamMember = $(this).parent();
		var fullText = $(this).parent().next();
		console.log(teamMember);
		console.log(fullText);

		teamMember.html(fullText.html());
	});
	
  },
  finalize() {
	$('.icon-columns__listing').slick({
		arrows: true,
		nextArrow: $('.custom-arrow--next'),
		prevArrow: $('.custom-arrow--prev'),
		dots: false,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		rows: 0,
		slides: '.icon-column',
		variableWidth: true,
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});
  },
};
